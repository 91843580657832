class TourLanguage {
  constructor(data) {
    this.id = data.id;
    this.name = data.name || '';
    this.title = data.title || '';
    this.metadescription = data.metadescription || '';
    this.language = data.language || '';
    this.description = data.description || '';
    this.include = data.include || '';
    this.important = data.important || '';
    this.recommendation = data.recommendation || '';
    // this.pictureTour = pictureTour || '';
  }
  toObject() {
    return {
      id:this.id,
      name:this.name,
      title:this.title,
      metadescription:this.metadescription,
      language:this.language,
      description:this.description,
      include:this.include,
      important:this.important,
      recommendation:this.recommendation,
    }
  }
}
var DataConverter =  {
  // Firestore data converter
  toFirestore: function(tour) {
    return {
      name: tour.name,
      title: tour.title,
      metadescription: tour.metadescription,
      language: tour.language,
      description: tour.description,
      include: tour.include,
      important: tour.important,
      recommendation: tour.recommendation,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new TourLanguage(data)
  }
}

export {TourLanguage, DataConverter} 