<template>
  <div>
    <portal to="title-page">
      <h1 v-if="tour">{{tour.name}}</h1>
    </portal>
    <tour-tabs v-if="tour" tab="info" :tour="tour">
      <v-card
        class="mx-auto elevation-1"
      >
        <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
        <v-card-title >
          <v-icon
            large
            left
          >
            {{mdiPencil}}
          </v-icon>
          <span class="title font-weight-light">Datos</span>
          <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-auto"><v-icon>{{mdiPlus}}</v-icon> lenguaje</v-btn>
          <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" md="4" class="py-0">
                <v-select
                  v-if="registerLanguages != null"
                  v-model="language"
                  :items="registerLanguages"
                  item-text="language"
                  return-object
                  :prepend-icon="mdiFlag"
                  :rules="[v => !!v || 'El idioma es requerido']"
                  label="Seleccione el idioma"
                  required
                  @change="changeLang"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8" class="py-0">
                <v-text-field
                  color="#f88511"
                  v-model="tourUrl"
                  required
                  :rules="[rules.required]"
                  :label="`Url en ` +(language ? language.language : '') "
                  :prepend-icon="mdiWeb"
                  
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" class="py-0">
                <v-text-field
                  color="#f88511"
                  v-model="tourLanguage.name"
                  required
                  :rules="[rules.required]"
                  label="Tour"
                  :prepend-icon="mdiText"
                  dense
                ></v-text-field>
              </v-col>
              <v-col md="4" class="py-0">
                <v-text-field
                  color="#f88511"
                  v-model="tourLanguage.title"
                  required
                  :rules="[rules.required]"
                  label="Titulo"
                  :prepend-icon="mdiFormatTitle"
                  dense
                ></v-text-field>
              </v-col>
              <v-col md="4" class="py-0">
                <v-select
                  v-model="tour.includeATVs"
                  :items="[{text:'No', value: 'no'},{text:'Si', value: 'yes'}]"
                  item-text="text"
                  item-value="value"
                  label="Incluye ATVs"
                  dense
                ></v-select>
              </v-col>
              <v-col md="12" class="py-0">
                <v-textarea
                  class=""
                  v-model="tourLanguage.metadescription"
                  label="Metadescripcion"
                  rows="2"
                  counter
                  hint="Se recomienda maximo 160 caracteres"
                  prepend-icon="mdi-comment"
                ></v-textarea>
              </v-col>
              <v-col md="6" class="py-0">
                <p>Descripcion</p>
                <vue-editor
                  :class="tourLanguage.description != null && tourLanguage.description == '' ? 'error' : ''"
                  color="#f88511"
                  v-model="tourLanguage.description"
                  :editor-toolbar="customToolbar"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="6" class="py-0">
                <p>Incluye</p>
                <vue-editor
                  :class="tourLanguage.include != null && tourLanguage.include == '' ? 'error' : ''"
                  color="#f88511"
                  v-model="tourLanguage.include"
                  :editor-toolbar="customToolbar"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="6" class="">
                <p>Importante</p>
                <vue-editor
                  :class="tourLanguage.important != null && tourLanguage.important == '' ? 'error' : ''"
                  color="#f88511"
                  v-model="tourLanguage.important"
                  :editor-toolbar="customToolbar"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="6" class="">
                <p>Recomendaciones</p>
                <vue-editor
                  :class="tourLanguage.recommendation != null && tourLanguage.recommendation == '' ? 'error' : ''"
                  color="#f88511"
                  v-model="tourLanguage.recommendation"
                  :editor-toolbar="customToolbar"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="12" class="my-4">
                <v-btn
                  :disabled="disableBtnAdd"
                  color="primary"
                  class="mr-4"
                  @click="validateForm()"
                >
                  <v-icon>{{mdiContentSave}}</v-icon> Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </tour-tabs>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              :prepend-icon="mdiFlag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Vue from "vue";
  import Tour from "@/models/Tour.js";
  import TourTabs from "@/components/admin/TourTabs";
  import { mdiArrowLeft, mdiPencil, mdiPlus, mdiFlag, mdiClose, mdiContentSave, mdiText, mdiFormatTitle, mdiWeb /*, mdiFilter*/ } from '@mdi/js';
  import Language from "@/models/Language.js";
  import {DataConverter} from "@/FirestoreDataConverters/TourLanguage.js";
  //import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
  //import Image from "@/models/ImageTour";
  Vue.use('tour-tabs', TourTabs);
  export default {
    name: "TourEdit",
    components: { TourTabs },
    data() {
      return {
        mdiArrowLeft:mdiArrowLeft,
        mdiPencil:mdiPencil,
        mdiPlus:mdiPlus,
        mdiFlag:mdiFlag,
        mdiClose:mdiClose,
        mdiWeb:mdiWeb,
        mdiContentSave:mdiContentSave,
        mdiText:mdiText,
        mdiFormatTitle:mdiFormatTitle,
        ///////////////////////////////////////////////
        tourModel:new Tour,
        languageModel:new Language,
        ///////////////////////////////////////////////
        tour:null,
        ref:null,
        unsubscribeLangs:null,
        tourLanguage:{
          name:null,
          title:null,
          metadescription:null,
          description:null,
          include:null,
          important:null,
          recommendation:null,
        },
        selectedLanguageId:'es',
        ///////////////////////////////////////////////
        systemLanguages:null,
        registerLanguages:null,
        availableLanguages:null,
        language:null,
        validForm:true,
        validFormInit:true,
        validFormLang:true,
        isLoading:true,
        newLang:null,
        unsubscribeTourUrl:null,
        tourUrl:'',
        ///////////////////////////////////////////////
        rules: {
          required: (v) => !!v || "El campo es obligatorio"
        },
        ///////////////////////////////////////////////
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        dialogAddLang:{
          show:false
        },
        ///////////////////////////////////////////////
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
      };
    },
    methods: {
      setAvailableLanguages() {
        this.availableLanguages = [];
        if (this.systemLanguages !== null) {
          if (this.registerLanguages !== null) {
            for(var i in this.systemLanguages) {
              let langId = this.systemLanguages[i].id;
              let exist = this.registerLanguages.find(item => item.id == langId);
              if (!exist) {
                this.availableLanguages.push(this.systemLanguages[i]);
              }
            }
          } else {
            this.availableLanguages = this.systemLanguages;
          }
        }
      },
      getLangs() {
        this.unsubscribeLangs = this.ref.collection('tourLanguages').withConverter(DataConverter).onSnapshot(docs => {
          this.registerLanguages = []
          var index, i = 0;
          docs.forEach(doc => {
            this.registerLanguages.push(doc.data());
            if (doc.data().id == this.selectedLanguageId) {
              index = i;
            }
            i++;
          })
          this.language = this.registerLanguages[index];
          this.tourLanguage = this.registerLanguages[index];
          this.setAvailableLanguages();
          this.isLoading = false;
        })
      },
      changeLang() {
        this.selectedLanguageId = this.language.id;
        //this.tourLanguage = {...this.language};
        this.tourLanguage = this.language;
        this.getUrl();
      },
      validateForm() {
        this.validFormInit = false;
        this.tourLanguage.description = this.tourLanguage.description == null ? '' : this.tourLanguage.description;
        this.tourLanguage.important = this.tourLanguage.important == null ? '' : this.tourLanguage.important;
        this.tourLanguage.recommendation = this.tourLanguage.recommendation == null ? '' : this.tourLanguage.recommendation;
        this.tourLanguage.include = this.tourLanguage.include == null ? '' : this.tourLanguage.include;

        if (this.$refs.form.validate() && !this.disableBtnAdd) {
          let loading = this.$loading.show();
          let prefix = this.language.id == 'es' ? '' : `/${this.language.id}`;
          let fullUrl = `${prefix}/tours/${this.tourUrl}`;
          this.tourModel.findUrl(fullUrl, this.language.id).get().then(async(snap) => {
            let tourIdUrl = snap.empty ? null : snap.docs[0].data().tourId;
            console.log('this.tourLanguage', this.tourLanguage);
            var tourData = this.tourLanguage.toObject()
            if (tourIdUrl == this.tourid || snap.empty) {
              //var tourData = this.tourLanguage;
              if (this.language.id == 'es') {
                this.ref.update({
                  name:this.tourLanguage.name,
                  title:this.tourLanguage.title,
                  includeATVs:this.tour.includeATVs,
                }).then(() => {
                  this.ref.collection('tourLanguages').doc(this.tourLanguage.id).update(tourData).then(() => {
                    this.ref.collection('tourUrls').doc(this.tourid+this.language.id).set({
                      tourId:this.tourid,
                      fullUrl:fullUrl,
                      url:this.tourUrl,
                      language:this.language.id,
                      deleted:0
                    }).then(() => {
                      loading.hide();
                      this.showSnackbarSuccess('El tour se actualizo correctamente');
                    })
                  })
                  .catch(error => {
                    console.log(error);
                    loading.hide();
                    this.showSnackbarError('Error al actualizar el lenguaje');
                  })
                })
                .catch(error => {
                  console.log(error);
                  loading.hide();
                  this.showSnackbarError('Error al actualizar el tour');
                })
              } else {
                await this.ref.update({
                  includeATVs:this.tour.includeATVs
                })
                this.ref.collection('tourLanguages').doc(this.tourLanguage.id).update(tourData).then(() => {
                  this.ref.collection('tourUrls').doc(this.tourid+this.language.id).set({
                    tourId:this.tourid,
                    fullUrl:fullUrl,
                    url:this.tourUrl,
                    language:this.language.id,
                    deleted:0
                  }).then(() => {
                    loading.hide();
                    this.showSnackbarSuccess('El tour se actualizo correctamente');
                  })
                })
                .catch(error => {
                  console.log(error);
                  loading.hide();
                  this.showSnackbarError('Error al actualizar el lenguaje');
                })
              }
            } else {
              this.showSnackbarError('La url "'+this.tourUrl+ '" ya esta registrada, ingrese otra');
              loading.hide();
            }
          });
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      addLang() {
        if (this.$refs.formlang.validate()) {
          let loading = this.$loading.show();
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.ref.collection('tourLanguages').doc(this.newLang.id).set({
            name:'',
            title:'',
            metadescription:'',
            description:'',
            important:'',
            recommendation:'',
            include:'',
            language:this.newLang.name,
          }).then(() => {
            this.getLangs();
            this.selectedLanguageId = this.newLang.id;
            loading.hide();
            this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
            this.dialogAddLang.show = false;
          });
        }
      },
      getUrl() {
        if (this.unsubscribeTourUrl) {
          this.unsubscribeTourUrl();
        }
        this.unsubscribeTourUrl = this.tourModel.findUrlByTour(this.tourid, this.selectedLanguageId).onSnapshot((snap) => {
          if (!snap.empty) {
            this.tourUrl = snap.docs[0].data().url;
          } else {
            this.tourUrl = '';
          }
        });
      }
    },
    mounted() {
      this.tourid = this.$route.params.Id;
      this.languageModel.getAll().onSnapshot(snap => {
        this.systemLanguages = [];
        snap.forEach( doc => {
          this.systemLanguages.push(doc.data());
        })
        this.setAvailableLanguages();
      })
      this.getUrl();
      this.tourModel.findByTour(this.tourid).onSnapshot((doc) => {
        this.ref = doc.ref;
        this.tour = doc.data();
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.getLangs();
      });
    },
    computed:{
      disableBtnAdd : function() {
        if (this.validFormInit) {
          return false;
        }
        if (!this.tourLanguage.description || !this.tourLanguage.important || !this.tourLanguage.recommendation || !this.tourLanguage.include) {
          return true;
        }
        return false;
      }
    },
    created() {
      this.$store.commit("SET_LAYOUT", "admin");
    },
  };
</script>
<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
#app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>